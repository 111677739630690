<template>
  <EntitySingle :config="config" :entity.sync="entity" :loaded.sync="loaded">
    <b-card v-if="loaded">
      <b-tabs pills>
        <!-- Tab: Info -->
        <b-tab active>
          <template #title>
            <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFields"
            allow-autofocus
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'

import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'

import storeModule from '../partnerRequestStoreModule'

import requestStatusOptions from '../requestStatusOptions'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    EntitySingle,
    EntityEditSection,
  },
  data() {
    return {
      entity: {},
      loaded: false,
      userOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'partner-requests',
        endpoint: 'partner-requests',
        route: 'partner-requests',
        title: {
          single: this.$i18n.t('Partner Request'),
          plural: this.$i18n.t('Partner Requests'),
        },
      }
    },

    infoFields() {
      return [
        {
          id: 'sender',
          object: true,
          type: 'select',
          label: this.$t('Sender'),
          options: this.senders,
          required: true,
          colSize: 3,
        },
        {
          id: 'receiver',
          object: true,
          type: 'select',
          label: this.$t('Receiver'),
          options: this.receivers,
          required: true,
          colSize: 3,
        },
        {
          id: 'status',
          type: 'select',
          label: this.$t('Partnership Status'),
          options: requestStatusOptions,
          required: true,
          colSize: 3,
        },
      ]
    },

    senders() {
      return this.userOptions.filter(u => !this.entity.receiver || u.id !== this.entity.receiver.id)
    },

    receivers() {
      return this.userOptions.filter(u => !this.entity.sender || u.id !== this.entity.sender.id)
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchUsers').then(response => {
      this.userOptions = response.data
    })
  },
}
</script>
